import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import config from '../assets/script/config';

const routes = [{
    path: '/',
    redirect: '/home',
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "page" */ '@/views/Home.vue'),
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/journals',
    name: 'journals',
    redirect: '/journals/journal',
    meta: { keepAlive: true, title: 'APPName' },
    children: [{
        path: 'journalOnline',
        name: 'journalOnline',
        component: () => import(/* webpackChunkName: "page" */ '@/views/JournalOnline.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'journal',
        name: 'journal',
        component: () => import(/* webpackChunkName: "page" */ '@/views/Journal.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'journalList',
        name: 'journalList',
        component: () => import(/* webpackChunkName: "page" */ '@/views/JournalList.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'journalPanel',
        name: 'journalPanel',
        component: () => import(/* webpackChunkName: "page" */ '@/views/JournalPanel.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'journalAuthorPanel',
        name: 'journalAuthorPanel',
        component: () => import(/* webpackChunkName: "page" */ '@/views/JournalAuthorPanel.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'journalFirst',
        name: 'journalFirst',
        component: () => import(/* webpackChunkName: "page" */ '@/views/JournalFirst.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'journalAnnualPanel',
        name: 'journalAnnualPanel',
        component: () => import(/* webpackChunkName: "page" */ '@/views/JournalAnnualPanel.vue'),
        meta: { keepAlive: true, title: 'APPName' },
        // }, {
        //     path: 'JDetail/:cid/:viewtype?',
        //     name: 'JDetailBack',
        //     component: JDetail,
        //     meta: { keepAlive: true, title: 'APPName' },
    }]
}, {
    path: '/JDetail/:cid/:viewtype?',
    name: 'JDetail',
    component: () => import(/* webpackChunkName: "page" */ '@/views/JDetail.vue'),
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/JFirstDetail/:cid/:viewtype?',
    name: 'JFirstDetail',
    component: () => import(/* webpackChunkName: "page" */ '@/views/JFirstDetail.vue'),
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/online',
    name: 'online',
    component: () => import(/* webpackChunkName: "page" */ '@/views/Online.vue'),
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "page" */ '@/views/Contact.vue'),
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/ordering',
    name: 'ordering',
    component: () => import(/* webpackChunkName: "page" */ '@/views/Ordering.vue'),
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/mine',
    name: 'mine',
    component: () => import(/* webpackChunkName: "page" */ '@/views/Mine.vue'),
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/books',
    name: 'books',
    redirect: '/books/book',
    meta: { keepAlive: true, title: 'APPName' },
    children: [{
        path: 'book',
        name: 'book',
        component: () => import(/* webpackChunkName: "page" */ '@/views/Book.vue'),
        meta: { keepAlive: true, title: 'APPName' }
    }, {
        path: 'bookLight',
        name: 'bookLight',
        component: () => import(/* webpackChunkName: "page" */ '@/views/BookLight.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'bookList',
        name: 'bookList',
        component: () => import(/* webpackChunkName: "page" */ '@/views/BookList.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'bookSuccession',
        name: 'bookSuccession',
        component: () => import(/* webpackChunkName: "page" */ '@/views/BookSuccession.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'bookSuccessionList',
        name: 'bookSuccessionList',
        component: () => import(/* webpackChunkName: "page" */ '@/views/BookSuccessionList.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: '/BDetail',
        name: 'BDetail',
        component: () => import(/* webpackChunkName: "page" */ '@/views/BDetail.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: '/ChapterDetail',
        name: 'ChapterDetail',
        component: () => import(/* webpackChunkName: "page" */ '@/views/ChapterDetail.vue'),
        meta: { keepAlive: true, title: 'APPName' },
    }]
}, {
    path: '/journalabbr',
    name: 'journalabbr',
    component: () => import(/* webpackChunkName: "page" */ '@/views/JournalAbbr.vue'),
    meta: { keepAlive: true, title: 'APPName' },
}, {
    //404 到首页
    path: '/404',
    name: '404page',
    redirect: '/home',
    meta: { keepAlive: true, title: 'APPName' },
}, {
    // 未知路由重定向
    path: '/:pathMatch(.*)',
    redirect: '/journalabbr',
    hidden: true
}];
//createWebHistory
//createWebHashHistory
const router = createRouter({
    history: createWebHistory(),
    base: config.PBASE,
    routes
});

// const routerPush = VueRouter.prototype.push,
//     routerReplace = VueRouter.prototype.replace;

// VueRouter.prototype.push = function push (location) {
//     return routerPush.call(this, location).catch(error => error)
// }

// VueRouter.prototype.replace = function push (location) {
//     return routerReplace.call(this, location).catch(error => error)
// }

export default router;
